import React, { useCallback, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { GetResult, GenerateResult, QueueResult } from '../services';
import DetailPage from '../components/DetailPage';
import medicallaboratory from '../img/medicallaboratory.png';
import { useParams } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import DetailsList from '../components/DetailsList';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';
import RoshanMap from '../components/RoshanMap';
import { createSampleMarker } from '../utils/mapHelper';

const mapContainerStyle = {
  height: '400px',
  width: '100%'
};

const defaultCenter = {
  lat: 53.5461,
  lng: -113.4938
};

const SampleMap = ({ sample, aiPredictions }) => {
  const marker = sample?.latitude && sample?.longitude
    ? [createSampleMarker(sample, aiPredictions)]
    : [];

  return <RoshanMap markers={marker} singleMarkerZoom={17} defaultZoom={17} />;
};

const TestRecordDetailsList = ({ data }) => {
  const fields = [
    [
      { key: 'bacteriaTypeName', label: 'Bacteria Type', icon: 'fa-flask' },
      { key: 'waterTypeName', label: 'Water Type', icon: 'fa-water' },
      { key: 'statusName', label: 'Status', icon: 'fa-rotate' },
    ],
    [
      {
        key: 'company.name',
        label: 'Company Name',
        icon: 'fa-building',
        render: (name) => data.company ? generateLink('company', data.company.companyId, name, 'fa-building') : name
      },
      {
        key: 'device.name',
        label: 'Device Name',
        icon: 'fa-microchip',
        render: (name) => data.device ? generateLink('device', data.device.deviceId, name, 'fa-microchip') : name
      }
    ],
    [
      {
        key: 'setDateTimeLocal',
        label: 'Test Date/Time',
        icon: 'fa-calendar-clock',
        render: (value) => formatCompactDateTime(value)
      },
      { key: 'slide', label: 'Slide Number', icon: 'fa-microscope' },
    ],
    [
      {
        key: 'cloudResultId',
        label: 'Cloud Result ID',
        icon: 'fa-cloud',
        render: (id) => generateLink('testRecord', id, id, 'fa-cloud')
      },
      { key: 'linkToken', label: 'Link Token', icon: 'fa-link' },
    ],
  ];

  return <DetailsList data={data} fields={fields} />;
};

const TimelineBar = ({ sampleTime, testTime }) => {
  const getTimeStatus = useCallback(() => {
    if (!sampleTime || !testTime) return null;

    const sampleDate = new Date(sampleTime);
    const testDate = new Date(testTime);
    const diffHours = (testDate - sampleDate) / (1000 * 60 * 60);

    if (diffHours <= 8) {
      return {
        color: '#1cc88a',
        status: 'Optimal',
        icon: 'fa-circle-check',
        description: 'Sample tested within recommended 8-hour window'
      };
    }
    if (diffHours <= 24) {
      return {
        color: '#f6c23e',
        status: 'Warning',
        icon: 'fa-triangle-exclamation',
        description: 'Sample tested within 24-hour acceptable window'
      };
    }
    return {
      color: '#e74a3b',
      status: 'Critical',
      icon: 'fa-circle-exclamation',
      description: 'Sample tested after recommended 24-hour window'
    };
  }, [sampleTime, testTime]);

  const status = getTimeStatus();
  if (!status) return null;

  const sampleDateFormatted = new Date(sampleTime).toLocaleString();
  const testDateFormatted = new Date(testTime).toLocaleString();
  const diffHours = ((new Date(testTime) - new Date(sampleTime)) / (1000 * 60 * 60)).toFixed(1);

  return (
    <div className="card mt-4">
      <div className="card-header py-3 d-flex align-items-center">
        <i className={`fas ${status.icon} mr-2`} style={{ color: status.color }}></i>
        <h6 className="m-0 font-weight-bold text-primary">Sample Timeline</h6>
      </div>
      <div className="card-body">
        <div className="timeline-container">
          <div className="d-flex align-items-center mb-3">
            <div className="timeline-icon">
              <i className="fas fa-flask" style={{ color: '#4e73df' }}></i>
            </div>
            <div className="timeline-content ml-3">
              <div className="font-weight-bold">Sample Collected</div>
              <div className="text-muted small">{sampleDateFormatted}</div>
            </div>
          </div>

          <div className="timeline-line" style={{
            background: `linear-gradient(to right, ${status.color}, ${status.color})`,
            height: '4px',
            margin: '0 20px',
            position: 'relative'
          }}>
            <div className="timeline-duration" style={{
              position: 'absolute',
              top: '-10px',
              width: '100%',
              textAlign: 'center'
            }}>
              <span className="badge badge-light" style={{ border: `1px solid ${status.color}` }}>
                {diffHours} hours
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <div className="timeline-icon">
              <i className="fas fa-microscope" style={{ color: '#4e73df' }}></i>
            </div>
            <div className="timeline-content ml-3">
              <div className="font-weight-bold">Sample Tested</div>
              <div className="text-muted small">{testDateFormatted}</div>
            </div>
          </div>

          <div className="timeline-status mt-3 p-2" style={{
            backgroundColor: `${status.color}15`,
            borderLeft: `4px solid ${status.color}`,
            borderRadius: '4px'
          }}>
            <div className="d-flex align-items-center">
              <i className={`fas ${status.icon} mr-2`} style={{ color: status.color }}></i>
              <div>
                <div className="font-weight-bold" style={{ color: status.color }}>
                  {status.status}
                </div>
                <div className="small text-muted">
                  {status.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SampleDetailsList = ({ sample, aiPredictions, testDateTime }) => {
  const fields = [
    [
      { key: 'cloudId', label: 'Sample ID', icon: 'fa-hashtag' },
      { key: 'localId', label: 'Local ID', icon: 'fa-fingerprint' },
      { key: 'waterType', label: 'Water Type', icon: 'fa-water' },
    ],
    [
      { key: 'collectionsSite', label: 'Collection Site', icon: 'fa-map-location' },
      { key: 'residualChlorine', label: 'Residual Chlorine', icon: 'fa-vial' },
      { key: 'observations', label: 'Observations', icon: 'fa-microscope' },
    ],
    [
      {
        key: 'setDateTimeUtc',
        label: 'Sampling Date/Time',
        icon: 'fa-calendar-clock',
        render: (value) => formatCompactDateTime(value)
      },
    ],
  ];

  return (
    <>
      <DetailsList data={sample} fields={fields} />
      <TimelineBar
        sampleTime={sample?.setDateTimeUtc}
        testTime={testDateTime}
      />
      <div className="card mt-4">
        <div className="card-header">
          <h6 className="m-0 font-weight-bold text-primary">
            <i className="fa-solid fa-map mr-2"></i>
            Sample Location
          </h6>
        </div>
        <div className="card-body">
          <div style={{ height: '400px', width: '100%' }}>
            <SampleMap sample={sample} aiPredictions={aiPredictions} />
          </div>
        </div>
      </div>
    </>
  );
};

const AIPredictionsList = ({ aiPredictions }) => {
  const fields = [
    [
      { key: 'concentration', label: 'Count (CFU/100 ml)', icon: 'fa-chart-line' },
    ],
    [
      { key: 'modelModelVersion', label: 'Model Version', icon: 'fa-code-branch' },
    ],
  ];

  return <DetailsList data={aiPredictions} fields={fields} />;
};

const TestRecordDetailsPage = () => {
  const { id } = useParams();

  const handleQueue = async () => {
    await QueueResult(id);
  };

  const handleDownload = async (downloadLink) => {
    window.open(downloadLink, '_blank', 'noopener,noreferrer');
  };

  const DetailComponent = ({ data }) => (
    <>
      <Row>
        <Col md={12}>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-gray-800">Test Record Details</h6>
            </div>
            <div className="card-body">
              <TestRecordDetailsList data={data} />
            </div>
          </div>
        </Col>
      </Row>
      {data.sample ? (
        <Row>
          <Col md={12}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-gray-800">Sample Details</h6>
              </div>
              <div className="card-body">
                <SampleDetailsList
                  sample={data.sample}
                  aiPredictions={data.aiPredictions}
                  testDateTime={data.setDateTimeLocal}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <Alert color="info">
              No sample information is available for this test record.
            </Alert>
          </Col>
        </Row>
      )}
      {data.aiPredictions ? (
        <Row>
          <Col md={12}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-gray-800">AI Predictions</h6>
              </div>
              <div className="card-body">
                <AIPredictionsList aiPredictions={data.aiPredictions} />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <Alert color="info">
              AI predictions are not available for this test record.
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );

  const actions = (data) => {
    if (!data.downloadStatus) {
      return [{
        label: "Queue Result",
        icon: "fa-clock",
        onClick: handleQueue,
        className: "btn-secondary"
      }];
    }

    if (data.downloadStatusName === 'Processing') {
      return [{
        label: "Processing",
        icon: "fa-spinner fa-spin",
        className: "btn-secondary disabled"
      }];
    }

    if (data.downloadStatusName === 'Completed' && data.downloadLink) {
      return [{
        label: "Download Result",
        icon: "fa-download",
        onClick: () => handleDownload(data.downloadLink),
        className: "btn-primary"
      }];
    }

    return [];
  };

  return (
    <DetailPage
      title="Result Details"
      fetchDataFunction={GetResult}
      DetailComponent={DetailComponent}
      backUrl="/test-records"
      iconSrc={medicallaboratory}
      actions={actions}
    />
  );
};

export default TestRecordDetailsPage;
