import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { Helmet } from "react-helmet";
import { useQuery } from 'react-query';
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetSamples, GetDropDown } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';
import plasticbottle from '../img/plasticbottle.png';
import RoshanMap from '../components/RoshanMap';
import { createSampleListMarker } from '../utils/mapHelper';

const SampleList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const columns = [
    { field: 'sampleId', header: 'ID', sortable: true },
    { field: 'collectionsSite', header: 'Collection Site', sortable: false },
    { field: 'waterType', header: 'Water Type', sortable: false },
    { field: 'residualChlorine', header: 'Residual Chlorine', sortable: false },
    {
      field: 'userFullName',
      header: 'User',
      render: (value, row) => generateLink('user', row.userId, row.userFullName, 'fa-user'),
      sortable: false,
    },
    {
      field: 'company.name',
      header: 'Company',
      render: (value, row) => generateLink('company', row.company.companyId, row.company.name, 'fa-building'),
      sortable: false,
    },
    {
      field: 'setDateTimeUtc',
      header: 'Date & Time',
      render: renderDateTime,
      sortable: false,
    },
  ];

  const handleDetails = (sampleId) => {
    navigate(`/samples/${sampleId}?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Details', onClick: handleDetails, color: 'primary' },
  ];

  return (
    <EntityList
      entityName="samples"
      entities={data?.samples || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const SampleMapView = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mapMarkersKey = useRef(Date.now()).current;  // Add unique key for map remounting

  useEffect(() => {
    window.google = window.google || {};
    window.google.maps = window.google.maps || {};
    window.google.maps.customMarkers = {
      handleDetailsClick: (sampleId) => {
        navigate(`/samples/${sampleId}?${searchParams.toString()}`);
      }
    };

    return () => {
      if (window.google?.maps?.customMarkers) {
        delete window.google.maps.customMarkers;
      }
    };
  }, [navigate, searchParams]);

  const markers = data?.samples
    ?.filter(sample =>
      sample?.latitude &&
      sample?.longitude &&
      !isNaN(parseFloat(sample.latitude)) &&
      !isNaN(parseFloat(sample.longitude))
    )
    .map(sample => createSampleListMarker(sample, true)) || [];

  return <RoshanMap key={mapMarkersKey} markers={markers} height="600px" />;
};

const SampleListView = () => {
  const [viewMode, setViewMode] = useState('list');
  const navigate = useNavigate();
  const { data: companies } = useQuery("getCompanies", GetDropDown);
  const [searchParams] = useSearchParams();

  const handleGenerateQRCode = async () => {
    navigate(`/samples/generate-qr?${searchParams.toString()}`);
  };

  const filterOptions = [
    { field: 'sampleId', label: 'Sample ID', type: 'text' },
    {
      field: 'CompanyId', label: 'Company', type: 'select', options:
        companies ? Object.entries(companies).map(([value, label]) => ({ value, label })) : []
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Sample Bottles</title>
      </Helmet>
      <TopNav imageSrc={plasticbottle} text="Sample Bottles" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Sample Bottles</h1>
            <div className="d-flex align-items-center">
              <ButtonGroup className="mr-2">
                <Button
                  color={viewMode === 'list' ? 'primary' : 'secondary'}
                  onClick={() => setViewMode('list')}
                >
                  <i className="fas fa-list fa-sm mr-1" />
                  List
                </Button>
                <Button
                  color={viewMode === 'map' ? 'primary' : 'secondary'}
                  onClick={() => setViewMode('map')}
                >
                  <i className="fas fa-map fa-sm mr-1" />
                  Map
                </Button>
              </ButtonGroup>
              <Button color="primary" onClick={handleGenerateQRCode}>
                <i className="fa-solid fa-qrcode fa-sm text-white-50 mr-2"></i>
                Generate QR Code
              </Button>
            </div>
          </div>
          <ListPage
            fetchDataFunction={GetSamples}
            ListComponent={viewMode === 'list' ? SampleList : SampleMapView}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SampleListView;