import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { Helmet } from "react-helmet";
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetResults, GenerateCSVResult, GetDropDown, QueueResult } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import medicallaboratory from '../img/medicallaboratory.png';
import { generateLink } from '../util/linkHelper';
import { useQuery } from 'react-query';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import RoshanMap from '../components/RoshanMap';
import { createTestRecordMarker } from '../utils/mapHelper';

const ResultList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleQueue = async (id) => {
    await QueueResult(id);
  };

  const columns = [
    { field: 'bacteriaTypeName', header: 'Test Type', sortable: true },
    { field: 'waterTypeName', header: 'Water Type', sortable: true },
    { field: 'statusName', header: 'Status', sortable: false },
    { field: 'device.name', header: 'Device', sortable: false, render: (value, row) => generateLink('device', row.device.id, row.device.name, 'fa-desktop') },
    { field: 'sample.collectionsSite', header: 'Collection Site', sortable: false },
    { field: 'aiPredictions.concentration', header: 'Count (CFU/100 ml)', sortable: false },
    { field: 'slide', header: 'Slide Number', sortable: true },
    {
      field: 'setDateTimeLocal',
      header: 'Test Date',
      render: renderDateTime,
      sortable: false
    },
    {
      field: 'actions',
      header: 'Actions',
      width: '150px',
      render: (value, row) => {
        const buttonStyle = {
          marginRight: '0.5rem'
        };

        return (
          <div className="d-flex align-items-center">
            {row.downloadStatus === 2 ? (
              row.downloadStatusName === 'Completed' ? (
                <Button
                  color="primary"
                  href={row.downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={buttonStyle}
                >
                  <i className="fas fa-download fa-sm mr-1" />
                  Download
                </Button>
              ) : (
                <Button
                  color="secondary"
                  disabled
                  style={buttonStyle}
                >
                  <i className="fas fa-spinner fa-spin fa-sm mr-1" />
                  Processing
                </Button>
              )
            ) : (
              <Button
                color="secondary"
                onClick={() => handleQueue(row.id)}
                style={buttonStyle}
              >
                <i className="fas fa-clock fa-sm mr-1" />
                Queue
              </Button>
            )}
            <Button
              color="info"
              onClick={() => handleDetails(row.cloudResultId)}
            >
              <i className="fas fa-info-circle fa-sm mr-1" />
              Details
            </Button>
          </div>
        );
      },
    }
  ];

  const handleDetails = (cloudResultId) => {
    navigate(`/test-records/${cloudResultId}?${searchParams.toString()}`);
  };

  return (
    <EntityList
      entityName="results"
      entities={data?.results || []}
      columns={columns}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const defaultCenter = {
  lat: 53.5461, // Edmonton's approximate latitude
  lng: -113.4938 // Edmonton's approximate longitude
};

const mapContainerStyle = {
  height: '600px',
  width: '100%'
};

const TestRecordMapView = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Add handler for marker details click
  useEffect(() => {
    window.google = window.google || {};
    window.google.maps = window.google.maps || {};
    window.google.maps.customMarkers = {
      handleDetailsClick: (cloudResultId) => {
        navigate(`/test-records/${cloudResultId}?${searchParams.toString()}`);
      }
    };

    return () => {
      // Cleanup
      if (window.google?.maps?.customMarkers) {
        delete window.google.maps.customMarkers;
      }
    };
  }, [navigate, searchParams]);

  const markers = data?.results
    ?.filter(record =>
      record?.sample?.latitude &&
      record?.sample?.longitude &&
      !isNaN(parseFloat(record.sample.latitude)) &&
      !isNaN(parseFloat(record.sample.longitude))
    )
    .map(record => createTestRecordMarker(record, true)) || [];

  return <RoshanMap markers={markers} height="600px" />;
};

const TestRecordListView = () => {
  const [viewMode, setViewMode] = useState('list');
  const { data: companies } = useQuery("getCompanies", GetDropDown);

  const handleDownloadCSV = async () => {
    await GenerateCSVResult();
  };

  const filterOptions = [
    {
      field: 'bacteriaTypeName', label: 'Test Type', type: 'select', options: [
        { value: 'E.coli', label: 'E.coli' },
        { value: 'Total Coliform', label: 'Coliform' },
      ]
    },
    {
      field: 'waterTypeName', label: 'Water Type', type: 'select', options: [
        { value: 'Drinking', label: 'Drinking' },
        { value: 'Other', label: 'Other' },
        { value: 'NA', label: 'Unknow' },
      ]
    },
    //slide number
    { field: 'slide', label: 'Slide Number', type: 'text' },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Test Records</title>
      </Helmet>
      <TopNav imageSrc={medicallaboratory} text="Test Records" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Test Records</h1>
            <div className="d-flex align-items-center">
              <ButtonGroup className="mr-2">
                <Button
                  color={viewMode === 'list' ? 'primary' : 'secondary'}
                  onClick={() => setViewMode('list')}
                >
                  <i className="fas fa-list fa-sm mr-1" />
                  List
                </Button>
                <Button
                  color={viewMode === 'map' ? 'primary' : 'secondary'}
                  onClick={() => setViewMode('map')}
                >
                  <i className="fas fa-map fa-sm mr-1" />
                  Map
                </Button>
              </ButtonGroup>
              <Button color="primary" onClick={handleDownloadCSV}>
                <i className="fas fa-download fa-sm text-white-50 mr-2" />
                Download CSV
              </Button>
            </div>
          </div>
          <ListPage
            fetchDataFunction={GetResults}
            ListComponent={viewMode === 'list' ? ResultList : TestRecordMapView}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TestRecordListView;