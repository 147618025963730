import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

export const EntityTable = ({
    entities,
    columns,
    onSort,
    sortField,
    sortAscending,    
    actions = [] // Add default empty array for actions
}) => {
    useEffect(() => {
        console.log('EntityTable received new entities:', entities);
    }, [entities]);

    if (!entities || entities.length === 0) {
        return <div>No data to display.</div>;
    }

    const renderCellValue = (entity, column) => {
        if (column.render) {
            return column.render(entity[column.field], entity);
        }

        const fieldParts = column.field.split('.');
        let value = entity;
        for (const part of fieldParts) {
            value = value?.[part];
            if (value === undefined) break;
        }
        return value ?? '';
    };

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.field} className="bg-light">
                                <div className="d-flex align-items-center">
                                    {column.sortable ? (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => onSort(column.field)}
                                        >
                                            {column.header}
                                           
                                        </span>
                                    ) : (
                                        column.header
                                    )}
                                    {column.sortable && (
                                        <i
                                            className={`fas fa-sort${sortField === column.field ? (sortAscending ? '-up' : '-down') : ''} ml-2 cursor-pointer`}
                                            onClick={() => onSort(column.field)}
                                        ></i>
                                    )}
                                </div>
                            </th>
                        ))}
                        {actions.length > 0 && <th className="bg-light">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {entities.map((entity) => (
                        <tr key={entity.id}>
                            {columns.map((column) => (
                                <td key={`${entity.id}-${column.field}`}>
                                    {renderCellValue(entity, column)}
                                </td>
                            ))}
                            {actions.length > 0 && (
                                <td>
                                    {actions.map((action, index) => (
                                        <Button
                                            key={`${entity.id}-${action.label}`}
                                            color={action.color}
                                            size="sm"
                                            onClick={() => action.onClick(entity.id)}
                                            className="mr-1"
                                        >
                                            {action.label}
                                        </Button>
                                    ))}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};