import React from 'react';
import { GetSample } from '../services';
import DetailPage from '../components/DetailPage';
import plasticbottle from '../img/plasticbottle.png';
import DetailsList from '../components/DetailsList';
import RoshanMap from '../components/RoshanMap';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';
import { createSampleListMarker } from '../utils/mapHelper';

const SampleMap = ({ data }) => {
  const marker = data?.latitude && data?.longitude
    ? [createSampleListMarker(data)]
    : [];

  return <RoshanMap markers={marker} singleMarkerZoom={17} defaultZoom={17} />;
};

const SampleDetailsList = ({ data }) => {
  const fields = [
    [
      { key: 'sampleId', label: 'Sample ID', icon: 'fa-hashtag' },
      { key: 'qrCode', label: 'QR Code', icon: 'fa-qrcode' },
      { key: 'waterType', label: 'Water Type', icon: 'fa-water' },
    ],
    [
      { key: 'collectionsSite', label: 'Collection Site', icon: 'fa-map-location' },
      { key: 'residualChlorine', label: 'Residual Chlorine', icon: 'fa-vial' },
      { key: 'observations', label: 'Observations', icon: 'fa-microscope' },
    ],
    [
      {
        key: 'company.name',
        label: 'Company',
        icon: 'fa-building',
        render: (value, row) => generateLink('company', row.company.companyId, value, 'fa-building')
      },
      {
        key: 'userFullName',
        label: 'User',
        icon: 'fa-user',
        render: (value, row) => generateLink('user', row.userId, value, 'fa-user')
      },
    ],
    [
      {
        key: 'setDateTimeUtc',
        label: 'Sampling Date/Time',
        icon: 'fa-calendar-alt',
        render: (value) => formatCompactDateTime(value)
      },
    ],
    [
      { key: 'localId', label: 'Local ID', icon: 'fa-fingerprint' },
      { key: 'cloudId', label: 'Cloud ID', icon: 'fa-cloud' },
    ],
  ];

  return (
    <>
      <DetailsList data={data} fields={fields} />
      <div className="card mt-4">
        <div className="card-header">
          <h6 className="m-0 font-weight-bold text-primary">
            <i className="fa-solid fa-map mr-2"></i>
            Location
          </h6>
        </div>
        <div className="card-body">
          <div style={{ height: '400px', width: '100%' }}>
            <SampleMap data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

const SampleDetailsPage = () => {
  return (
    <DetailPage
      title="Sample Details"
      fetchDataFunction={GetSample}
      DetailComponent={SampleDetailsList}
      backUrl="/samples"
      iconSrc={plasticbottle}
    />
  );
};

export default SampleDetailsPage;