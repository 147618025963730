import React, { useCallback, useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

const defaultCenter = {
    lat: 53.5461,
    lng: -113.4938
};

const mapContainerStyle = {
    width: '100%',
    height: '100%'
};

const RoshanMap = ({
    markers = [],
    defaultZoom = 11,
    singleMarkerZoom = 16,  // Changed default
    height = '400px',
    autoFitBounds = true,
    onMapLoad
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    const mapRef = useRef(null);
    const markersRef = useRef([]);
    const activeInfoWindowRef = useRef(null);

    const fitBounds = useCallback(() => {
        if (!mapRef.current || !markersRef.current.length) return;

        if (markersRef.current.length === 1) {
            // For single marker, just center and zoom
            const position = markersRef.current[0].getPosition();
            mapRef.current.setCenter(position);
            mapRef.current.setZoom(singleMarkerZoom);
            return;
        }

        // For multiple markers, fit bounds
        const bounds = new window.google.maps.LatLngBounds();
        markersRef.current.forEach(marker => bounds.extend(marker.getPosition()));
        mapRef.current.fitBounds(bounds);
    }, [singleMarkerZoom]);

    const createMarkers = useCallback(() => {
        if (!mapRef.current) return;

        // Clear existing markers
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];

        if (activeInfoWindowRef.current) {
            activeInfoWindowRef.current.close();
            activeInfoWindowRef.current = null;
        }

        markers.forEach(({ position, icon, title, content }) => {
            const marker = new window.google.maps.Marker({
                position,
                map: mapRef.current,
                icon: {
                    url: icon,
                    scaledSize: new window.google.maps.Size(32, 32),
                    anchor: new window.google.maps.Point(16, 32)
                },
                title
            });

            if (content) {
                const infoWindow = new window.google.maps.InfoWindow({ content });

                marker.addListener('click', () => {
                    if (activeInfoWindowRef.current) {
                        activeInfoWindowRef.current.close();
                    }
                    infoWindow.open(mapRef.current, marker);
                    activeInfoWindowRef.current = infoWindow;
                });

                // If it's a single marker, open info window by default
                if (markers.length === 1) {
                    infoWindow.open(mapRef.current, marker);
                    activeInfoWindowRef.current = infoWindow;
                }
            }

            markersRef.current.push(marker);
        });

        if (autoFitBounds) {
            fitBounds();
        }
    }, [markers, autoFitBounds, fitBounds]);

    const onLoad = useCallback((map) => {
        mapRef.current = map;
        if (onMapLoad) {
            onMapLoad(map);
        }
        // Create markers after map is loaded
        createMarkers();
    }, [onMapLoad, createMarkers]);

    const onUnmount = useCallback(() => {
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
        if (activeInfoWindowRef.current) {
            activeInfoWindowRef.current.close();
        }
        mapRef.current = null;
    }, []);

    // This effect will handle updates to markers
    useEffect(() => {
        if (mapRef.current && markers.length > 0) {
            createMarkers();
        }
    }, [markers, createMarkers]);

    // Handle cleanup
    useEffect(() => {
        return () => {
            if (markersRef.current) {
                markersRef.current.forEach(marker => marker.setMap(null));
                markersRef.current = [];
            }
            if (activeInfoWindowRef.current) {
                activeInfoWindowRef.current.close();
            }
            mapRef.current = null;
        };
    }, []);

    if (!isLoaded) return <div>Loading map...</div>;

    return (
        <div style={{ height }}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={defaultCenter}
                zoom={defaultZoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
            />
        </div>
    );
};

export default RoshanMap;
